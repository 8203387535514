import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

const messages = {
  en: {
    productions: "productions",
    coproductions: "co-productions",
    cinema: "cinema",
    all: "all",
    news: "news",
    history: "history",
    contact: "contact",
    imprint: "imprint",
    privacypolicy: "privacy policy",
  },
  de: {
    productions: "Produktionen",
    coproductions: "Co-Produktionen",
    cinema: "Kino",
    all: "Alle",
    news: "Aktuelles",
    history: "Firmengeschichte",
    contact: "Kontakt",
    imprint: "Impressum",
    privacypolicy: "Datenschutz",
  },
};

const i18n = new VueI18n({
  locale: "de", // set locale
  fallbackLocale: "de",
  messages, // set locale messages
});

export default i18n;
