<template>
  <div class="home">
    <ImageSlider />
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {
    ImageSlider: () => import("@/components/ImageSlider.vue"),
  },
};
</script>
