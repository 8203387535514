import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    cmsUrl: process.env.VUE_APP_API_URL,
    token: process.env.VUE_APP_TOKEN,
    screenWidth: null,
    screenBreakXL: 992,
    screenBreakLG: 992,
    activeMenuPoint: null,
    navOpen: false,
    curLang: "DE",
    showLangBox: true,
    languages: [
      {
        ID: 1,
        title: "Deutsch",
        short: "DE",
        tag: "de-DE",
        image: "",
        active: true,
      },
      {
        ID: 2,
        title: "English",
        short: "EN",
        tag: "en-En",
        image: "",
        active: false,
      },
    ],
  },
  getters: {},
  mutations: {
    updateScreenWidth(state, width) {
      state.screenWidth = width;
    },
    updateActiveMenuPoint(state, ID) {
      state.activeMenuPoint = ID;
    },
    updateNavOpen(state, status) {
      state.navOpen = status;
    },
    updateLanguage(state, lang) {
      state.curLang = lang;
    },
    setCurLang(state, lang) {
      state.curLang = lang;
    },
  },
  actions: {
    setScreenWidth({ commit }) {
      let width = window.innerWidth;
      commit("updateScreenWidth", width);
    },
    updateCurLang({ dispatch, commit }, lang) {
      commit("setCurLang", lang);
      dispatch("init_DataPull");
    },
  },
  modules: {},
});
