<template>
  <div id="app">
    <HeaderPart />
    <main role="main">
      <transition name="toggleMain">
        <router-view :key="$route.fullPath" />
      </transition>
    </main>
    <FooterPart />
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapState([
      "cmsUrl",
      "screenWidth",
      "screenBreakXL",
      "activeMenuPoint",
      "navOpen",
      "token",
      "pages",
    ]),
    page: {
      get() {
        return (
          this.pages.find(
            (page) => page.attributes.pagename === this.$route.name
          )?.attributes || {}
        );
      },
      deep: true,
    },
  },
  components: {
    HeaderPart: () => import("@/components/templateParts/HeaderPart.vue"),
    FooterPart: () => import("@/components/templateParts/FooterPart.vue"),
  },

  methods: {
    handleResize() {
      this.$store.dispatch("setScreenWidth");
    },
  },
  created() {
    this.$store.dispatch("init_DataPull");
  },
  mounted() {
    // Set ScreenWidth
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="sass">
@import '@/assets/sass/main.sass'
</style>
